define('carnival-frontend/models/app', ['exports', 'ember-data', 'carnival-frontend/models/targetable', 'carnival-frontend/models/custom-event-source', 'carnival-frontend/adapters/application', 'carnival-frontend/config/environment', 'carnival-frontend/helpers/certificate-type'], function (exports, _emberData, _targetable, _customEventSource, _application, _environment, _certificateType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _targetable.default.extend({
    name: _emberData.default.attr('string'),
    iconAsset: _emberData.default.attr('file'), // js File() object
    iconAssetUrl: _emberData.default.attr('string'), // can storage base64 string for using in <img src=''>
    key: _emberData.default.attr('string'),
    platform: _emberData.default.attr('string'),
    bundleIdentifier: _emberData.default.attr('string'),
    teamId: _emberData.default.attr('string'),
    keyId: _emberData.default.attr('string'),
    isAdmin: _emberData.default.attr('boolean'),
    isBilling: _emberData.default.attr('boolean'),
    isAuthor: _emberData.default.attr('boolean'),
    isDeveloper: _emberData.default.attr('boolean'),
    googleGcmApiKey: _emberData.default.attr('string'),
    googleProjectID: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    hasPushCredentials: _emberData.default.attr('boolean'),
    certificateExpiresAt: _emberData.default.attr('date'),
    disabled: _emberData.default.attr('boolean'),
    accountState: _emberData.default.attr('string'),
    disabledCustomEventsSources: _emberData.default.attr('jsonArray', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    messageAttributeKeys: _emberData.default.attr('jsonArray', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    starredEvents: _emberData.default.attr('jsonArray', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    whitelistedDomains: _emberData.default.attr('jsonArray', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    sailthruKey: _emberData.default.attr('string'),
    sailthruClientID: _emberData.default.attr('string'),
    maxPushRate: _emberData.default.attr('number'),

    sailthruFrontendUrl: _emberData.default.attr('string', {
      defaultValue: _environment.default.myFrontend
    }),

    pushEnabledCount: 0,
    pushQuietCount: 0,

    PUSH_RATE_RANGE: { min: 1, max: 100000 },
    API_RATE_LIMIT_RANGE: { min: 120, max: 2000 },
    PURCHASE_ATTRIBUTION_WINDOW_RANGE: { min: 1, max: 24 },

    isAdminOrDeveloper: Ember.computed.or('isAdmin', 'isDeveloper'),
    canAuthorMessages: Ember.computed.or('isAdmin', 'isAuthor'),

    abTests: _emberData.default.hasMany('ab-test', { inverse: 'app' }),
    importAudienceJobs: _emberData.default.hasMany('import-audience-job'),
    messages: _emberData.default.hasMany('message', { async: false }),
    notifications: _emberData.default.hasMany('notification', { async: false }),
    permissions: _emberData.default.hasMany('permission', { inverse: 'app' }),
    apiClients: _emberData.default.hasMany('api-client', { inverse: 'interfaceable' }),
    audiences: _emberData.default.hasMany('audience', { inverse: 'app' }),
    devices: _emberData.default.hasMany('device', { inverse: 'app' }),

    permittedAudience: _emberData.default.belongsTo('audience'),
    settings: _emberData.default.attr('hash'),
    pushPerformance: _emberData.default.attr('hash'),

    currencySymbol: _emberData.default.attr('string'),
    purchaseAttributionWindow: _emberData.default.attr('number'),
    apiRateLimit: _emberData.default.attr('number', {
      defaultValue: function defaultValue(app) {
        return app.API_RATE_LIMIT_RANGE.min;
      }
    }),

    inAppMessages: Ember.computed.filterBy('messages', 'isPushType', false),
    pushMessages: Ember.computed.filterBy('messages', 'isPushType', true),

    allParentAudiences: Ember.computed.filterBy('audiences', 'parentAudience', null),
    parentAudiences: Ember.computed.filterBy('allParentAudiences', 'disabled', false),

    isCustomEventSourceActive: function isCustomEventSourceActive(source) {
      return !this.get('disabledCustomEventsSources').includes(source);
    },


    customEventsSources: Ember.computed(function () {
      return [_customEventSource.default.create({ app: this, label: 'Google Analytics' }), _customEventSource.default.create({ app: this, label: 'Localytics' }), _customEventSource.default.create({ app: this, label: 'Mixpanel' }), _customEventSource.default.create({ app: this, label: 'Adobe Analytics' }), _customEventSource.default.create({ app: this, label: 'Flurry Analytics' }), _customEventSource.default.create({ app: this, label: 'Amplitude' })];
    }),

    toggleCustomEventSourceState: function toggleCustomEventSourceState(source) {
      if (this.isCustomEventSourceActive(source)) {
        this.get('disabledCustomEventsSources').addObject(source);
      } else {
        this.get('disabledCustomEventsSources').removeObject(source);
      }

      this.save();
    },


    deviceCount: Ember.computed(function () {
      var _this = this;

      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/device_count';

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'GET').then(function (json) {
          _this.set('pushEnabledCount', json.total_push_enabled);
          _this.set('pushQuietCount', json.total_push_quiet);
          return json.total;
        })
      });
    }),

    fetchCompactAudiences: function fetchCompactAudiences() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('audience'),
          data = {};

      data.id = this.id;
      data.compact = true;

      return adapter.ajax(url, 'GET', { data: data }).then(function (json) {
        return json.audiences;
      });
    },


    audiencesThatHaveRevenueData: Ember.computed(function () {
      var adapter = this.get('store').adapterFor('app');
      var url = adapter.buildURL('app', this.get('id')) + '/audiences?have_revenue_data=true';
      var request = adapter.ajax(url, 'GET');

      return _emberData.default.PromiseArray.create({
        promise: request.then(function (json) {
          return json.audiences;
        })
      });
    }),

    fetchCertificate: function fetchCertificate(type) {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('certificates', this.get('id')) + '?type=' + type;

      return adapter.ajax(url, 'GET').then(function (response) {
        var cert = response.certificate || {};
        if (Object.keys(cert).length !== 0) cert.type = type;
        return cert;
      });
    },


    p8Certificate: Ember.computed(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.fetchCertificate(_certificateType.default.P8)
      });
    }),

    developmentCertificate: Ember.computed(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.fetchCertificate(_certificateType.default.DEV_P12)
      });
    }),

    productionCertificate: Ember.computed(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.fetchCertificate(_certificateType.default.PROD_P12)
      });
    }),

    fetchStats: function fetchStats(params) {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/stats/live',
          data = params || {};

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'GET', {
          data: data
        })
      });
    },
    fetchTechnology: function fetchTechnology(options) {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/stats/technology',
          data = {};

      if (options.from && options.to) {
        data.from = options.from.toISOString();
        data.to = options.to.toISOString();
      }

      if (options.platforms && options.platforms.length) {
        data.platforms = options.platforms;
      }

      return adapter.ajax(url, 'GET', { data: data });
    },
    fetchGeography: function fetchGeography(options) {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/stats/geography',
          data = {};

      if (options.from && options.to) {
        data.from = options.from.toISOString();
        data.to = options.to.toISOString();
      }
      return adapter.ajax(url, 'GET', { data: data });
    },
    removeUser: function removeUser() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/delete_permission';

      return adapter.ajax(url, 'DELETE');
    },
    removeCertificate: function removeCertificate(type) {
      var store = this.get('store');
      var adapter = store.adapterFor('app'),
          url = adapter.buildURL('certificates') + '/' + this.get('id') + '?type=' + type;

      return adapter.ajax(url, 'DELETE');
    },
    enable: function enable() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/enable';

      return adapter.ajax(url, 'POST').then(function (json) {
        store.pushPayload('app', json);
      });
    },
    convertCertificateToPem: function convertCertificateToPem(data) {
      var adapter = _application.default.create(),
          url = adapter.buildURL('certificates', 'convert');

      return adapter.ajax(url, 'POST', { data: { certificate: data } });
    },
    saveCertificate: function saveCertificate(data, type) {
      var adapter = _application.default.create();
      var url = adapter.buildURL('certificates');

      return adapter.ajax(url, 'POST', {
        data: { id: this.get('id'), certificate: data, type: type }
      });
    },
    testCertConnection: function testCertConnection(type) {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('certificates') + '/' + this.get('id') + '/validate_connection?type=' + type;

      return adapter.ajax(url, 'GET');
    },
    testFCMConnection: function testFCMConnection() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/fcm_validate_key';

      return adapter.ajax(url, 'GET');
    },
    fetchServiceAccount: function fetchServiceAccount() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = '/web/v1/fcm/' + this.get('id');

      return adapter.ajax(url, 'GET').then(function (response) {
        return response.fcm_service_account || {};
      });
    },


    serviceAccount: Ember.computed(function () {
      return _emberData.default.PromiseObject.create({
        promise: this.fetchServiceAccount()
      });
    }),

    saveServiceAccount: function saveServiceAccount(service_account) {
      var adapter = _application.default.create();
      var url = '/web/v1/fcm';

      return adapter.ajax(url, 'POST', {
        data: { id: this.get('id'), fcm_service_account: service_account }
      });
    },
    removeServiceAccount: function removeServiceAccount() {
      var store = this.get('store');
      var adapter = store.adapterFor('app'),
          url = '/web/v1/fcm/' + this.get('id');

      return adapter.ajax(url, 'DELETE');
    },
    testFCMServiceAccountConnection: function testFCMServiceAccountConnection() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = '/web/v1/fcm/' + this.get('id') + '/validate_service_account';

      return adapter.ajax(url, 'GET');
    },


    subscriptions: Ember.computed(function () {
      var adapter = this.get('store').adapterFor(this.constructor.modelName),
          url = adapter.buildURL('subscriptions'),
          app_id = this.get('id');

      return _emberData.default.PromiseArray.create({
        promise: adapter.ajax(url, 'GET').then(function (json) {
          return json.subscriptions.filterBy('app_id', app_id);
        })
      });
    }),

    subscribeTo: function subscribeTo(type) {
      var subscriptions = this.get('subscriptions'),
          adapter = this.get('store').adapterFor(this.constructor.modelName),
          url = adapter.buildURL('subscriptions');
      var params = {
        data: {
          subscription: {
            app_id: this.get('id'),
            type: type
          }
        }
      };
      adapter.ajax(url, 'POST', params).then(function (json) {
        if (!json || !json.subscription) {
          return;
        }
        subscriptions.addObject(json.subscription);
      });
    },
    unsubscribeFrom: function unsubscribeFrom(subscription) {
      if (!subscription) {
        return null;
      }

      var subscriptions = this.get('subscriptions'),
          adapter = this.get('store').adapterFor('user'),
          url = adapter.buildURL('subscriptions', subscription.id);

      return adapter.ajax(url, 'DELETE').then(function () {
        subscriptions.removeObject(subscription);
      });
    },


    adminStats: Ember.computed(function () {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/stats/admin';

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'GET')
      });
    }),

    customEvents: Ember.computed(function () {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/custom_events';

      return _emberData.default.PromiseArray.create({ promise: adapter.ajax(url, 'GET') });
    }),

    customEventTotal: function customEventTotal(eventId, params) {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/stats/custom_event_total/' + eventId,
          data = params || {};

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'GET', { data: data }).then(function (data) {
          return data.event_count || 0;
        })
      });
    },
    notificationsSentTotal: function notificationsSentTotal() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('app', this.get('id')) + '/stats/notifications_sent_total';

      return _emberData.default.PromiseObject.create({
        promise: adapter.ajax(url, 'GET').then(function (data) {
          return data.count || 0;
        })
      });
    },
    getImportLocationsSign: function getImportLocationsSign() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('import_locations_job');
      url += '/new?app_id=' + this.id;

      return adapter.ajax(url, 'GET');
    },
    sendImportLocationsJob: function sendImportLocationsJob(filename) {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('import_locations_job'),
          data = { app_id: this.id };

      data.import_locations_job = {
        filename: filename
      };

      return adapter.ajax(url, 'POST', { data: data }).then(function (res) {
        store.pushPayload('import_locations_job', res);
      });
    },
    getImportAudienceSign: function getImportAudienceSign() {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('import_audience_job');

      url += '/new?app_id=' + this.id;

      return adapter.ajax(url, 'GET');
    },
    sendImportAudienceName: function sendImportAudienceName(filename, audienceName) {
      var store = this.get('store'),
          adapter = store.adapterFor('app'),
          url = adapter.buildURL('import_audience_job'),
          data = { app_id: this.id };

      data.import_audience_job = {
        filename: filename,
        audience_name: audienceName
      };

      return adapter.ajax(url, 'POST', { data: data }).then(function (res) {
        store.pushPayload('import_audience_job', res);
      });
    },
    fetchAnnotations: function fetchAnnotations(from, to) {
      var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
          _ref$revenueAttributa = _ref.revenueAttributableOnly,
          revenueAttributableOnly = _ref$revenueAttributa === undefined ? false : _ref$revenueAttributa,
          _ref$audienceId = _ref.audienceId,
          audienceId = _ref$audienceId === undefined ? '' : _ref$audienceId;

      return this.store.filter('annotation', {
        app_id: this.id,
        from: from,
        to: to,
        revenue_attributable_only: revenueAttributableOnly,
        audience_id: audienceId
      }, function (annotation) {
        return annotation.visibleForAudience(audienceId);
      });
    }
  });
});