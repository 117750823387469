define('carnival-frontend/controllers/settings/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_POOL_SIZE = 25;

  exports.default = Ember.Controller.extend({
    messageBuilder: Ember.inject.service(),
    benchmarkState: 'idle',
    benchmarkButtonClass: Ember.computed('benchmarkState', function () {
      switch (this.benchmarkState) {
        case 'idle':
          return 'btn-primary';
        case 'failed':
          return 'btn-destruct';
        default:
          return 'btn-secondary';
      }
    }),

    weeklyReportSubscription: Ember.computed('model.subscriptions.[]', 'currentUserService.user', {
      get: function get() {
        return !!this.get('model.subscriptions').findBy('type', 'weekly_report');
      },
      set: function set(key, value) {
        var app = this.get('model'),
            subscription = app.get('subscriptions').findBy('type', 'weekly_report');

        if (value) {
          this.alert.info('Subscription', 'Your subscription status is changed.');
          app.subscribeTo('weekly_report');
        } else {
          this.alert.info('Subscription', 'Your subscription status is changed.');
          app.unsubscribeFrom(subscription);
        }

        return !!subscription;
      }
    }),

    certificateReminderSubscription: Ember.computed('model.subscriptions.[]', 'currentUserService.user', {
      get: function get() {
        return !!this.get('model.subscriptions').findBy('type', 'certificate_reminder');
      },
      set: function set(key, value) {
        var app = this.get('model'),
            subscription = app.get('subscriptions').findBy('type', 'certificate_reminder');

        if (value) {
          this.alert.info('Subscription', 'Your subscription status is changed.');
          app.subscribeTo('certificate_reminder');
        } else {
          this.alert.info('Subscription', 'Your subscription status is changed.');
          app.unsubscribeFrom(subscription);
        }

        return !!subscription;
      }
    }),

    throttleRange: Ember.computed.reads('model.PUSH_RATE_RANGE'),
    DEFAULT_THROTTLE: 10000,

    throttle: Ember.computed('model.maxPushRate', {
      get: function get() {
        var rate = parseFloat(this.get('model.maxPushRate'));

        // if rate gets set to an invalid value
        if (isNaN(rate)) return 'none';

        return 'custom';
      },
      set: function set(key, value) {
        var rate = parseFloat(this.get('model.maxPushRate'));

        // reset rate when switching to none
        if (value === 'none') this.set('model.maxPushRate', null);

        // set to default rate when switching to custom
        if (value === 'custom' && isNaN(rate)) this.set('model.maxPushRate', this.DEFAULT_THROTTLE);

        return value;
      }
    }),

    apiRateLimitRange: Ember.computed.reads('model.API_RATE_LIMIT_RANGE'),
    attributionWindowRange: Ember.computed.reads('model.PURCHASE_ATTRIBUTION_WINDOW_RANGE'),

    // eslint-disable-next-line ember/no-observers
    slidersDidChange: Ember.observer('model.apiRateLimit', 'model.maxPushRate', 'model.purchaseAttributionWindow', 'model.pushPerformance.poolSize', function () {
      if (!Ember.testing) Ember.run.debounce(this, this.saveModel, 500);
    }),

    // eslint-disable-next-line ember/no-observers
    currencySymbolDidChange: Ember.observer('model.currencySymbol', function () {
      this.saveModel();
    }),

    oldPoolSize: DEFAULT_POOL_SIZE,

    poolSizeProxy: Ember.computed('model.pushPerformance.poolSize', 'oldPoolSize', {
      get: function get() {
        return this.getWithDefault('model.pushPerformance.poolSize', this.get('oldPoolSize'));
      },
      set: function set(_, value) {
        this.updateModelPoolSizeWithForcedDirtyness(parseInt(value));
        return value;
      }
    }),

    poolSizeSetting: Ember.computed('model.pushPerformance.poolSize', {
      get: function get() {
        var workers = this.get('model.pushPerformance.poolSize');
        if (Ember.isPresent(workers)) {
          return 'custom';
        }
        return 'default';
      },
      set: function set(_, value) {
        if (value === 'default') {
          this.set('oldPoolSize', this.get('model.pushPerformance.poolSize'));
          this.updateModelPoolSizeWithForcedDirtyness(null);
        } else if (value === 'custom') {
          this.updateModelPoolSizeWithForcedDirtyness(this.get('oldPoolSize'));
          this.set('oldPoolSize', null);
        }
        return value;
      }
    }),

    updateModelPoolSizeWithForcedDirtyness: function updateModelPoolSizeWithForcedDirtyness(size) {
      this.set('model.pushPerformance.poolSize', size);
      // I had to guess this after reading the source for the Ember Data state machine.
      // https://github.com/emberjs/data/blob/master/addon/-private/system/model/states.js#L258
      // This may stop working at any time in the future.  You're welcome future me.
      this.get('model').send('becomeDirty');
    },


    currencySymbols: [{ label: '$', value: '$' }, { label: '£', value: '£' }, { label: '€', value: '€' }, { label: '¥', value: '¥' }, { label: '¤', value: '¤' }],

    saveModel: function saveModel() {
      return this.send('save');
    },


    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');

        if (!model.get('hasDirtyAttributes')) return;
        model.get('errors').clear();

        return model.save().then(function () {
          _this.alert.success('Success', 'Your app was successfully updated.');
        }, function () {
          _this.alert.danger('Failed', 'There was a problem updating your app.');
        });
      },
      enable: function enable() {
        var _this2 = this;

        return this.get('model').enable().then(function () {
          _this2.alert.success('Success', 'This app was successfully re-enabled.');
        }).catch(function () {
          _this2.alert.danger('Failed', 'There was a problem re-enabling this app.');
        });
      },
      sendError: function sendError() {
        throw new Error('testing 1, 2, 3');
      },
      sendBenchmark: function sendBenchmark() {
        var _this3 = this;

        var message = this.messageBuilder.newMessage({
          app: this.model,
          benchmark: true,
          targetable: this.model,
          title: null
        }, false);

        message.notifications.firstObject.set('title', 'Benchmark message');

        this.set('benchmarkState', 'sending');

        var resetState = function resetState() {
          if (!_this3.isDestroyed) {
            _this3.set('benchmarkState', 'idle');
          }
        };

        return message.save().then(function () {
          if (!_this3.isDestroyed) {
            _this3.alert.success('Success', 'Benchmark push successfully sent.');
            _this3.set('benchmarkState', 'sent');
            Ember.run.later(_this3, resetState, 3000);
          }
        }).catch(function () {
          if (!_this3.isDestroyed) {
            _this3.alert.danger('Failed', 'There was a problem sending your benchmark push.');
            _this3.set('benchmarkState', 'failed');
            Ember.run.later(_this3, resetState, 3000);
          }
        });
      }
    }
  });
});